import {
	Box,
	Flex,
	HStack,
	Spacer,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tooltip,
	Tr,
	Text,
	VStack
} from '@chakra-ui/react';
import { ImportantIcon } from '../../../../components/icons/ImportantIcon';
import { formatDateWithoutTime } from '../../../../utils/StringUtils';
import { TenderItem } from '../../../../models/Tender';

export function TenderInfo({ tender }) {
	const time = tender?.finishDate;
	const date = new Date(time!);
	const handleDelivery = tender?.delivery ? 'Yes' : 'No';

	const tenderItems: TenderItem[] | undefined = tender?.items;

	return (
		<Box px={10} py={4}>
			<Flex
				justifyContent={'space-around'}
				marginTop={3}
				p={2}
				border={'1px'}
				borderColor={'gray.500'}
				rounded={'md'}
			>
				<VStack mb={'4'}>
					<HStack>
						<Text fontWeight={'bold'} fontSize={'xl'}>
							Description:
						</Text>
						<Text fontSize={'lg'}>{tender?.description}</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'} fontSize={'xl'}>
							Terms:
						</Text>
						<Text fontSize={'lg'}>{tender?.terms}</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'} fontSize={'xl'}>
							Status:
						</Text>
						<Text fontSize={'lg'}>
							{tender?.status === 1 ? 'Published' : 'Not published'}
						</Text>
					</HStack>
				</VStack>

				<HStack mb={'4'}>
					<VStack mr={'3'}>
						<HStack>
							<Text fontWeight={'bold'} fontSize={'xl'}>
								Address:
							</Text>
							<Text fontSize={'lg'}>{tender?.address}</Text>
						</HStack>
						<HStack>
							<Text fontWeight={'bold'} fontSize={'xl'}>
								Delivery:
							</Text>
							<Text fontSize={'lg'}>{handleDelivery}</Text>
						</HStack>
					</VStack>
					<Spacer />
					<VStack ml={'3'}>
						<Tooltip
							hasArrow
							label="You will not be able to answer offer until this date has passed"
						>
							<HStack>
								<Text fontWeight={'bold'} fontSize={'xl'}>
									Close Date:
								</Text>
								<Text fontSize={'lg'}>{formatDateWithoutTime(date)}*</Text>
							</HStack>
						</Tooltip>
						<HStack>
							<Text fontWeight={'bold'} fontSize={'xl'}>
								Phone:
							</Text>
							<Text fontSize={'lg'}>{tender?.phoneNumber}</Text>
						</HStack>
					</VStack>
				</HStack>
			</Flex>

			<Table variant={'striped'}>
				<Thead>
					<Tr>
						<Th width={'20%'}>
							<Tooltip hasArrow label="Cost code">
								<HStack>
									<Text>Number</Text>
									<ImportantIcon size={20} />
								</HStack>
							</Tooltip>
						</Th>

						<Th width={'20%'}>
							<Tooltip hasArrow label="Description of a item">
								<HStack>
									<Text>Description</Text>
									<ImportantIcon size={20} />
								</HStack>
							</Tooltip>
						</Th>

						<Th width={'20%'}>
							<Tooltip hasArrow label="Volume">
								<HStack>
									<Text color={'black'}>Volume</Text>
									<ImportantIcon size={20} />
								</HStack>
							</Tooltip>
						</Th>

						<Th width={'20%'}>
							<Tooltip hasArrow label="Unit of measurement. For example: m2, kg, t">
								<HStack>
									<Text>Unit</Text>
									<ImportantIcon size={20} />
								</HStack>
							</Tooltip>
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					<>
						{tenderItems?.map((item) => (
							<Tr key={item.tenderItemId}>
								<Td width={'20%'}>{item.nr}</Td>
								<Td width={'20%'}>{item.description}</Td>
								<Td width={'20%'}>{item.volume}</Td>
								<Td width={'20%'}>{item.unit}</Td>
							</Tr>
						))}
					</>
				</Tbody>
			</Table>
		</Box>
	);
}
